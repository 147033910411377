import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Guia Ràpida Coneixements Mossos d'Esquadra 2024 (5a edició)</Title>
                <Text>
                    La preparació per a l'oposició al Cos de Mossos d'Esquadra exigeix un constant
                    compromís, esforç, i una intensa dedicació. Només amb una tenaç perseverança,
                    l'aspirant pot garantir el seu èxit.
                    <br />
                    <br />
                    No obstant això, estem aquí per oferir-te el nostre suport i acompanyament en
                    aquesta llarga carrera cap al teu objectiu.
                    <br />
                    <br />
                    La nostra missió és facilitar-te una eina que et permeti abordar ràpidament els
                    continguts de la prova de coneixements, permetent-te així una preparació àgil i
                    eficaç per a la resta de les proves d'oposició.
                    <br />
                    <br />
                    Amb aquest llibre, aspirem a simplificar l'estudi del temari establert per la
                    Generalitat de Catalunya mitjançant resums concisos, esquemes visuals i taules
                    informatives. Destaquem les idees clau i les preguntes més rellevants dels
                    exàmens anteriors, incloent-hi els de l'oposició de l'any 2017.
                    <br />
                    <br />
                    La selecció de les eines de preparació per a una oposició és una decisió
                    personal. No obstant això, des de la nostra pròpia experiència i l'aplicació de
                    les tècniques d'estudi més efectives, hem creat una eina valuosa que desitgem
                    compartir amb tu.
                </Text>

                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuació us adjuntem un resum en format pdf del contingut del llibre.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <a
                            href={require("@static/documents/products/453/preview.pdf")}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Descarregar vista prèvia
                        </a>
                        <br />
                        <br />

                        <embed
                            src={require("@static/documents/products/453/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        />
                        <br />
                        <br />

                        <embed
                            src={require("@static/documents/products/453/correccions.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        />
                    </div>

                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/R1r6kx3Ev9Y"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
